const sectionAccordion = () => {
  const accordions = document.querySelectorAll(".accordion__item");

  if (accordions.length > 0) {
    accordions.forEach((accordion) => {
      const btn = accordion.querySelector(".accordion__btn");

      btn.addEventListener("click", () => {
        accordions.forEach((tab) => {
          if (tab.id === accordion.id) {
            tab.classList.add("show");
          } else {
            tab.classList.remove("show");
          }
        });
      });
    });
  }
};

export default sectionAccordion;
