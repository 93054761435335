const pageContacts = () => {
  const checkBoxes = document.querySelectorAll(".wpcf7-acceptance");

  if (checkBoxes.length > 0) {
    checkBoxes.forEach((checkBox) => {
      const input = checkBox.querySelector("input");
      input.addEventListener("click", () => {
        checkBox.classList.toggle("checked");
      });
    });
  }
};

export default pageContacts;
