const pageOsteria = () => {
  bannerLinkEvent(".home__cta");
  bannerLinkEvent("#osteria__banner");
  bannerLinkEvent("#visite__regala");
};

export default pageOsteria;

const bannerLinkEvent = (selector) => {
  const banner = document.querySelector(selector);
  if (banner) {
    const bannerLink = banner.querySelector(".wp-block-button__link");

    if (bannerLink) {
      banner.addEventListener("click", () => {
        window.open(bannerLink.href, bannerLink.target);
      });
    }
  }
};
