const pageShop = () => {
  // $(() => {
  //   /**
  //    * Filter Product By Category
  //    */

  //   const prodFilterList = document.getElementById("product__filter__list");
  //   if (prodFilterList) {
  //     //   Init List
  //     const prodFilterItems = prodFilterList.querySelectorAll(".product__filter__item");
  //     if (prodFilterItems.length > 0) {
  //       prodFilterItems.forEach((item) => {
  //         const btn = item.querySelector(".product__filter__btn");
  //         btn.addEventListener("click", () => {
  //           dataFilter(btn.value);
  //           prodFilterItems.forEach((option) => {
  //             option.classList.remove("current");
  //           });
  //           item.classList.add("current");
  //         });
  //       });
  //     }
  //   }
  // });

  /**
   * 
   */

  setTimeout(() => {
    const products = document.querySelectorAll(".product");
    if (products.length > 0) {
    }
  }, 1000);
};

export default pageShop;

// /**
//  * Filter Product
//  *
//  * @param {string} term
//  */

// const dataFilter = (term) => {

//   $(".loader-spinner").removeClass("hidden");

//   $.ajax({
//     type: "POST",
//     dataType: "json",
//     url: ajax_auth_object.ajaxurl,
//     data: {
//       action: "my_action",
//       security: ajax_auth_object.security,
//       lang: ajax_auth_object.languagecode,
//       term: term,
//     },
//     success: (res) => {
//       $(".loader-spinner").addClass("hidden");
//       if (res.data === "empty") {
//         console.log("La ricerca non ha prodotto risultati.");
//       } else {
//         dataClear();
//         dataList(res.data);
//         console.log("La ricerca ha prodotto " + res.data.length + " risultati.");
//       }
//     },
//     error: (e) => {
//       $(".loader-spinner").addClass("hidden");
//       console.log("Errore " + e.status + ": " + e.statusText);
//     },
//   });
// };

// /**
//  * List Products
//  *
//  * @param {array} data
//  */

// const dataList = (data) => {
//   $.each(data, (index, prod) => {
//     let result = '<article class="product col-12 col-md-6 col-lg-4">';
//     result += '<div class="product__content">';
//     result += prod.thumbnail ? prod.thumbnail : '<span class="thumb-placeholder"><i class="icon-camera"></i></span>';
//     result += '<h3 class="product__title"><a href="' + prod.permalink + '">' + prod.title + "</a></h3>";
//     result += '<div class="product__short-desc">' + prod.excerpt + "</div>";
//     result += '<div class="product__footer">'
//     result += prod.price ? '<span class="product__price">' + prod.price + '</span>' : "";
//     result += '<div class="product__buttons">';
//     result += prod.add_to_cart ? '<a href="' + prod.add_to_cart + '" data-quantity="1" class="button product_type_simple add_to_cart_button ajax_add_to_cart" data-product_id="' + prod.id + '" data-product_sku="" aria-label="Aggiungi al carrello: &quot;' + prod.title + '&quot;" aria-describedby="" rel="nofollow">Aggiungi al carrello</a>' : "";
//     result += "</div>";
//     result += "</div>";
//     result += "</div>";
//     result += "</article>";

//     $("#prod__results").append(result);
//   });
// };

// /**
//  * Clear Products
//  */

// const dataClear = () => {
//   let items = document.querySelectorAll("#prod__results .product");
//   if (items.length > 0) {
//     items.forEach((item) => {
//       item.remove();
//     });
//   }
// };
