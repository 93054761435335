const navigator = () => {
  /**
   * Dropdown
   */

  const dropdowns = document.querySelectorAll(".menu-item-has-children");
  if (dropdowns.length > 0) {
    dropdowns.forEach((dropdown) => {
      const link = dropdown.querySelector(".dropdown-toggle");
      const menu = dropdown.querySelector(".dropdown-menu");

      if (link && menu) {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          menu.classList.toggle("hide__menu");
        });
      }
    });
  }

  /**
   * Scroll Animation
   */

  window.addEventListener("scroll", () => {
    const navbar = document.querySelector(".nav__top");

    if (window.scrollY > 80) {
      navbar.classList.add("scrolled");
    } else {
      navbar.classList.remove("scrolled");
    }
  });

  /**
   * Close Menu at link click
   */

  const menu = document.getElementById("navbarToggler");
  const hamburger = document.querySelector(".navbar-toggler");
  if (menu) {
    const menuLinks = menu.querySelectorAll(".menu-item");
    if (menuLinks.length > 0) {
      menuLinks.forEach((link) => {
        if (!link.classList.contains("menu-item-has-children")) {
          link.addEventListener("click", () => {
            document.body.classList.remove("noscroll");
            menu.classList.remove("aperto");
            hamburger.classList.add("collapsed");
          });
        }
      });
    }
  }

  document.addEventListener("DOMContentLoaded", function () {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        setTimeout(() => {
          window.scrollTo({
            top: elementPosition,
            behavior: "smooth",
          });
        }, 500);
      }
    }
  });
};

export default navigator;
