/**
 * Export all the theme images
 */
import ArrowRightDark from "../img/icon-arrow-right-dark.svg";
import ArrowRightRed from "../img/icon-arrow-right-red.svg";
import ArrowRightWhite from "../img/icon-arrow-right-white.svg";
import CartDark from "../img/icon-cart-dark.svg";
import CartWhite from "../img/icon-cart-white.svg";
import Divider from "../img/icon-divider.svg";
import Minus from "../img/icon-minus.svg";
import Plus from "../img/icon-plus.svg";
import Star from "../img/icon-star.svg";
import VideoPlayer from "../img/icon-video-player.svg";
import VideoPause from "../img/icon-video-pause.svg";
import VideoMute from "../img/icon-video-mute.svg";
import VideoUnmute from "../img/icon-video-unmute.svg";
import Whatsapp from "../img/icon-whatsapp.svg";

const themeMedia = () => {
  newImage(ArrowRightDark);
  newImage(ArrowRightRed);
  newImage(ArrowRightWhite);
  newImage(CartDark);
  newImage(CartWhite);
  newImage(Divider);
  newImage(Minus);
  newImage(Plus);
  newImage(Star);
  newImage(VideoPlayer);
  newImage(VideoPause);
  newImage(VideoMute);
  newImage(VideoUnmute);
  newImage(Whatsapp);
};

/**
 * Compile image from src folder
 *
 * @param Image source
 */

const newImage = (source) => {
  const newImage = new Image();
  newImage.src = source;
};

export default themeMedia;
